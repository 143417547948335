import React, {Component} from 'react'
import {translate} from 'react-i18next'
import InputCheckbox from "../../../../components/shared/InputCheckbox";
import Button from "../../../../components/shared/Button/Button";
import Popup from "../../../../components/shared/Popup/Popup";
import Select from "../../../../components/shared/Select/Select";
import {RIM_SIZE_PRICE_TYPE} from "../../../../store/consts/tireLabours.constants";
import {TIRES_AXEL_FRONT, TIRES_AXEL_REAR} from "../../../../store/consts/tires.constants";

class LabourGroupItem extends Component {

    state = {
        showRimSizeSelect: false,
    }

    handleChange = (e) => {
        const {onChange} = this.props;

        e.target.checked ? this.setState({showRimSizeSelect: true}) : onChange(parseInt(e.target.value), false)
    }

    render() {
        const {t, labourGroup, onPriceUpdate, tiresProcess} = this.props;
        const {showRimSizeSelect} = this.state;

        const Labour = ({labour}) => {
            return <>
                <InputCheckbox
                    id={`tire-labour-${labourGroup.id}`}
                    name={`tire-labour-${labourGroup.id}`}
                    value={labourGroup.id}
                    checked={labourGroup.checked}
                    onChange={this.handleChange}
                    label={(
                        <>
                            <div className="name">
                                {labour.name}
                            </div>
                            {(labour.price_type === RIM_SIZE_PRICE_TYPE && labourGroup.checked) &&
                                <div className="desc">
                                    {labour.axel.front.selected && <div>{t('pages.form_steps.tires.axel.front')}: {labour.axel.front.rim_size}"</div>}
                                    {labour.axel.rear.selected && <div>{t('pages.form_steps.tires.axel.rear')}: {labour.axel.rear.rim_size}"</div>}
                                </div>
                            }
                        </>
                    )}
                    data={labour}
                />

                {showRimSizeSelect &&
                    <RimSizeSelect
                        t={t}
                        labour={labour}
                        tiresProcess={tiresProcess}
                        onSubmit={(axels) => {
                            onPriceUpdate(labourGroup.id, labour.id, axels)
                            this.setState({showRimSizeSelect: false})
                        }}
                        onClose={() => this.setState({showRimSizeSelect: false})}/>
                }
            </>
        }

        return (
            <>
                <div className="labour-item">
                    <div className="row">
                        <div className="col-sm-9">
                            {labourGroup.labours.length === 1 &&
                                <Labour labour={labourGroup.labours[0]} />
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class RimSizeSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            axels: {
                front: {
                    selected: props.labour.axel.front.rim_size ? props.labour.axel.front.selected : true,
                    rim_size: props.tiresProcess.tires[TIRES_AXEL_FRONT] ? props.tiresProcess.tires[TIRES_AXEL_FRONT].diameter : props.labour.axel.front.rim_size,
                },
                rear: {
                    selected: props.labour.axel.rear.rim_size ? props.labour.axel.rear.selected : true,
                    rim_size: props.tiresProcess.tires[TIRES_AXEL_REAR] ? props.tiresProcess.tires[TIRES_AXEL_REAR].diameter : props.labour.axel.rear.rim_size,
                }
            }
        }
    }

    validate = () => {
        const {labour} = this.props;
        const {axels} = this.state;

        if(labour.price_type === RIM_SIZE_PRICE_TYPE){
            if((axels.front.selected && !axels.front.rim_size)){
                return false;
            }
            else if(axels.rear.selected && !axels.rear.rim_size) {
                return false;
            }

            return true;
        }
        else {
            return axels.front.selected || axels.rear.selected;
        }
    }

    onRimSizeUpdate = (axel, option) => {
        const {axels} = this.state;

        this.setState({
            axels: {
                front: {
                    selected: axels.front.selected,
                    rim_size: option.id,
                },
                rear: {
                    selected: axels.rear.selected,
                    rim_size: option.id,
                }
            }
        })
    }

    render() {
        const {t, labour, onClose, onSubmit} = this.props;
        const {axels} = this.state;

        return (
            <Popup
                title={t('pages.form_steps.tires.labours.rim_size.title', {labour: labour.name})}
                visible={true}
                onClose={onClose}
            >
                {['front', 'rear'].map((axel) => (
                    <div className="row mb-20">
                        <div className="col-xs-12 col-sm-3 col-md-3">
                            <InputCheckbox
                                id={`tire-axel-${axel}`}
                                name={`tire-axel-${axel}`}
                                key={axel}
                                value={axel}
                                checked={axels[axel].selected}
                                onChange={(e) => this.setState({axels: {...axels, [axel]: {...axels[axel], selected: e.target.checked}}})}
                                label={t('pages.form_steps.tires.axel.' + axel)}
                            />
                        </div>

                        {labour.price_type === RIM_SIZE_PRICE_TYPE &&
                            <div className="col-xs-12 col-sm-3 col-md-6">
                                <Select
                                    id={`rim-size-${axel}`}
                                    list={Object.keys(labour.rim_size_prices).map(rim => ({id: parseInt(rim), name: `${rim}"`}))}
                                    selected={axels[axel].rim_size}
                                    onChange={(id, option) => this.onRimSizeUpdate(axel, option)}
                                    disabled={!axels[axel].selected}
                                    size="sm"
                                >
                                    {t('pages.form_steps.tires.labours.rim_size.select')}
                                </Select>
                            </div>
                        }
                    </div>
                ))}

                <div className="text-center">
                    <Button size="sm" type="primary"
                            onClick={() => onSubmit(axels)} disabled={!this.validate()}>{t('global.save')}</Button>
                </div>
            </Popup>
        )
    }
}

export default translate('translations')(LabourGroupItem);
