export const TIRES_AXEL_FRONT = 1;
export const TIRES_AXEL_REAR = 2;

export const TIRES_AXELS = [TIRES_AXEL_FRONT, TIRES_AXEL_REAR];

export const TIRES_AXEL_NAME = {[TIRES_AXEL_FRONT]: 'front', [TIRES_AXEL_REAR]: 'rear'};

export const TIRES_STEP_AXEL = 1;
export const TIRES_STEP_LIST = 2;

export const TIRES_STEPS = [TIRES_STEP_AXEL, TIRES_STEP_LIST];

export const TIRES_SEASON_SUMMER = 1;
export const TIRES_SEASON_WINTER = 2;
export const TIRES_SEASON_ALL_SEASON = 3;
export const TIRES_SEASON_NORDIC = 4;

export const TIRES_SORT_ASC = 'asc';
export const TIRES_SORT_DESC = 'desc';

export const TIRES_AXEL_RESET_FIELDS_BELLOW = {
    wide: [
        'depth',
        'depth_list',
        'diameter',
        'diameter_list',
    ],
    depth: [
        'diameter',
        'diameter_list',
    ],
};
