import labours from 'store/models/labours/labours'
import {LABOURS_RESET, LABOURS_SET} from "../consts/labours/labours.constants";

export default function(state = labours, action) {
    switch (action.type) {
        case LABOURS_SET:
            return [...action.payload.labours]

        case LABOURS_RESET:
            return labours

        default:
            return state
    }
}
