import React, {Component} from 'react'
import {translate} from 'react-i18next'
import {partQuantity, getWorks, getParts} from "../../../store/selectors/labours";
import BaseCountry from "../../../utils/BaseCountry";
import {sumBy} from "lodash"
import {fixedFloat, roundCeil, toBool} from "../../../utils/common";
import {
    brakeDiscount,
    partDiscount,
    sumPriceTotal, sumPriceTotalVat,
    sumSetPrice,
    sumWorkPrice
} from "../../../store/selectors/laboursPrice";
import {TIRES_AXEL_FRONT, TIRES_AXEL_REAR} from "../../../store/consts/tires.constants";
import {offerPriceTotal, offerPriceTotalWithTax} from "../../../store/selectors/offerPrice";

const OIL_ID = 444;

class RequestSpecification extends Component {

    sumOriginalTime = works => {
        let originalTime = 0

        works.forEach(work => {
            originalTime += parseFloat(work.original_time)
        })

        return roundCeil(originalTime)
    }

    sumDuration = works => {
        let duration = 0

        works.forEach(work => {
            duration += parseFloat(work.duration)
        })

        return roundCeil(duration)
    }

    render() {
        const {t, settings, recommendedOil} = this.props;

        const getPartSets = (parts) => {
            let partSets = [];

            parts.forEach(part => {
                part.versions.filter(v => v.selected).forEach(version => {
                    version.set.filter(s => s.selected).forEach(set => partSets.push(set))
                })
            })

            return partSets;
        }

        const Custom = () => {
            const {labours} = this.props;

            return labours.filter(l => l.custom === true)
        }

        const Labours = () => {
            const {labours} = this.props;

            return labours.filter(l => l.custom !== true)
        }

        const Parts = () => {

            let partSets = getPartSets(getParts(Labours()).filter(p => p.id !== OIL_ID));

            if(!partSets.length) return null

            return (
                <div className="spec-item">
                    <div className="spec-item-title"><i className="icon-gear"/> {t('pages.form_steps.overview.request_specification.parts_title')}</div>
                    <div className="spec-item-header">
                        <div className="col-sm-3">{t('pages.form_steps.overview.request_specification.serial_number')}</div>
                        <div className="col-sm-5 text-left">{t('pages.form_steps.overview.request_specification.part_name')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.quantity')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.price')}</div>
                    </div>
                    <div className="spec-item-list">
                        {partSets.map((partSet, key) => <div className="spec-item-list__item" key={key}>
                            <div className="col-sm-3">{partSet.partNo}</div>
                            <div className="col-sm-5 text-left">{partSet.name}</div>
                            <div className="col-sm-2">{partSet.amounts} {partQuantity(partSet)}</div>
                            <div className="col-sm-2">{roundCeil(partSet.price, true)} <span
                                className="currency">{BaseCountry().currency}</span></div>
                        </div>)}
                    </div>
                    <div className="spec-item-total">
                        <div className="col-sm-6">{t('global.total')}:</div>
                        <div className="col-sm-6">{roundCeil(sumBy(partSets, p => sumSetPrice(p)), true)} <span
                            className="currency">{BaseCountry().currency}</span></div>
                    </div>
                </div>
            );
        }

        const Works = () => {
            let works = getWorks(Labours());

            if(!works.length) return null

            return (
                <div className="spec-item">
                    <div className="spec-item-title"><i className="icon-clock"/> {t('pages.form_steps.overview.request_specification.works_title')}</div>
                    <div className="spec-item-header">
                        <div className="col-sm-4">{t('pages.form_steps.overview.request_specification.works_name')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.time')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.calc_time')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.hourly_price')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.price')}</div>
                    </div>
                    <div className="spec-item-list">
                        {works.map((work, key) => <div className="spec-item-list__item" key={key}>
                            <div className="col-sm-4">{work.name}</div>
                            <div className="col-sm-2">{work.original_time} <span className="currency">h</span></div>
                            <div className="col-sm-2">{work.duration} <span className="currency">h</span></div>
                            <div className="col-sm-2">{settings.hourly_price} <span
                                className="currency">{BaseCountry().currency}/h</span></div>
                            <div className="col-sm-2">{roundCeil(sumWorkPrice(work), true)} <span
                                className="currency">{BaseCountry().currency}</span></div>
                        </div>)}
                    </div>

                    <div className="spec-item-total">
                        <div className="col-sm-4">{t('global.total')}:</div>
                        <div className="col-sm-2 text-center">{this.sumOriginalTime(works)} <span className="currency">h</span></div>
                        <div className="col-sm-2 text-center">{this.sumDuration(works)} <span className="currency">h</span></div>
                        <div className="col-sm-4">{roundCeil(sumBy(works, w => sumWorkPrice(w)), true)} <span
                            className="currency">{BaseCountry().currency}</span></div>
                    </div>

                </div>
            );
        }

        const Oil = () => {

            let partSets = getPartSets(getParts(Labours()).filter(p => p.id === OIL_ID));

            if(!partSets.length) return null

            return (
                <div className="spec-item">
                    <div className="spec-item-title"><i className="icon-oil"/> {t('pages.form_steps.overview.request_specification.oil_title')}</div>

                    <RecommendedOil />

                    <div className="spec-item-header">
                        <div className="col-sm-3">{t('pages.form_steps.overview.request_specification.serial_number')}</div>
                        <div className="col-sm-5 text-left">{t('pages.form_steps.overview.request_specification.part_name')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.quantity')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.price')}</div>
                    </div>
                    <div className="spec-item-list">
                        {partSets.map((partSet, key) => <div className="spec-item-list__item" key={key}>
                            <div className="col-sm-3">{partSet.partNo}</div>
                            <div className="col-sm-5 text-left">{partSet.name}</div>
                            <div className="col-sm-2">{partSet.amounts} {partQuantity(partSet)}</div>
                            <div className="col-sm-2">{roundCeil(partSet.price, true)} <span
                                className="currency">{BaseCountry().currency}</span></div>
                        </div>)}
                    </div>
                    <div className="spec-item-total">
                        <div className="col-sm-6">{t('global.total')}:</div>
                        <div className="col-sm-6">{roundCeil(sumBy(partSets, p => sumSetPrice(p)), true)} <span
                            className="currency">{BaseCountry().currency}</span></div>
                    </div>
                </div>
            );
        }

        const CustomLabours = () => {

            let customLabours = Custom();

            if(customLabours.length === 0) return null

            return (
                <div className="spec-item">
                    <div className="spec-item-title">{t('pages.form_steps.overview.request_specification.custom_labours')}</div>
                    <div className="spec-item-header">
                        <div className="col-lg-9">{t('pages.form_steps.overview.request_specification.works_name')}</div>
                        <div className="col-lg-3">{t('pages.form_steps.overview.request_specification.price')}</div>
                    </div>
                    <div className="spec-item-list">
                        {customLabours.map((customLabour, key) => <div className="spec-item-list__item" key={key}>
                            <div className="col-lg-9">{customLabour.name}</div>
                            <div className="col-lg-3">{roundCeil(customLabour.priceFixed, true)} <span className="currency">{BaseCountry().currency}</span></div>
                        </div>)}
                    </div>
                    <div className="spec-item-total">
                        <div className="col-lg-6">{t('global.total')}:</div>
                        <div className="col-lg-6">{roundCeil(sumBy(customLabours, p => p.priceFixed), true)} <span className="currency">{BaseCountry().currency}</span></div>
                    </div>
                </div>
            );
        }

        const Total = () => {
            const {labours, tireLabours, tires} = this.props;

            return (
                <div className="spec-total">
                    <div className="spec-total__item">
                        <div className="col-sm-6">{t('pages.form_steps.overview.request_specification.price')}:</div>
                        <div className="col-sm-6">{roundCeil(offerPriceTotal(labours, tireLabours, tires), true)} <span className="currency">{BaseCountry().currency}</span></div>
                    </div>
                    {toBool(partDiscount(labours)) &&
                        <div className="spec-total__item">
                            <div className="col-sm-6">{t('pages.form_steps.overview.request_specification.parts_discount')}:</div>
                            <div className="col-sm-6">{roundCeil(partDiscount(labours), true)} <span className="currency">{BaseCountry().currency}</span></div>
                        </div>
                    }
                    {toBool(brakeDiscount(labours)) &&
                        <div className="spec-total__item">
                            <div
                                className="col-sm-6">{t('pages.form_steps.overview.request_specification.brakes_discount')}:
                            </div>
                            <div className="col-sm-6">{roundCeil(brakeDiscount(labours), true)} <span
                                className="currency">{BaseCountry().currency}</span></div>
                        </div>
                    }
                    <div className="spec-total__item">
                        <div className="col-sm-6">{t('global.vat')}:</div>
                        <div className="col-sm-6">{roundCeil(sumPriceTotalVat(offerPriceTotal(labours, tireLabours, tires)), true)} <span className="currency">{BaseCountry().currency}</span></div>
                    </div>
                    <div className="spec-total__item spec-total--total">
                        <div className="col-sm-6">{t('global.total')}:</div>
                        <div className="col-sm-6">{roundCeil(offerPriceTotalWithTax(labours, tireLabours, tires), true)} <span className="currency">{BaseCountry().currency}</span></div>
                    </div>
                </div>
            )
        }

        const RecommendedOil = () => {

            if(recommendedOil.oil.length === 0 && recommendedOil.viscosity.length === 0) return null

            return (
                <div className="spec-item-recommended-oil">
                    <div>{t('pages.form_steps.overview.request_specification.recommended_oil')}</div>
                    <div className="row">
                        <div className="col-sm-6">
                            {recommendedOil.oil.map((o, key) => {
                                return <div key={key}>{o.name} - {o.value}</div>
                            })}
                        </div>

                        <div className="col-sm-6 text-right">
                            {recommendedOil.viscosity.map((o, key) => {
                                return <div key={key}><b>{o.name}</b> - {o.value}</div>
                            })}
                        </div>
                    </div>
                </div>
            )
        }

        const TireLabours = () => {
            const {tireLabours} = this.props;

            if(!tireLabours.length) return null

            return (
                <div className="spec-item">
                    <div className="spec-item-title">{t('pages.form_steps.overview.request_specification.tire_labours_title')}</div>

                    <div className="spec-item-header">
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.works_name')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.tire_labours.front_size')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.tire_labours.rear_size')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.tire_labours.front_price')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.tire_labours.rear_price')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.price')}</div>
                    </div>
                    <div className="spec-item-list">
                        {tireLabours.map((tireLabour) => <div key={tireLabour.tire_labour_id} className="spec-item-list__item">
                            <div className="col-sm-2">{tireLabour.name}</div>
                            <div className="col-sm-2">{tireLabour.axel.front.selected ? `${tireLabour.axel.front.rim_size}"` : '/'}</div>
                            <div className="col-sm-2">{tireLabour.axel.rear.selected ? `${tireLabour.axel.rear.rim_size}"` : '/'}</div>
                            <div className="col-sm-2">
                                {tireLabour.axel.front.selected ?
                                    <>{fixedFloat(tireLabour.axel.front.price)} <span className="currency">{BaseCountry().currency}</span></> : '/'
                                }
                            </div>
                            <div className="col-sm-2">
                                {tireLabour.axel.rear.selected ?
                                    <>{fixedFloat(tireLabour.axel.rear.price)} <span className="currency">{BaseCountry().currency}</span></> : '/'
                                }
                            </div>
                            <div className="col-sm-2">{fixedFloat(tireLabour.price)} <span className="currency">{BaseCountry().currency}</span></div>
                        </div>)}
                    </div>
                    <div className="spec-item-total">
                        <div className="col-sm-6">{t('global.total')}:</div>
                        <div className="col-sm-6">{fixedFloat(sumBy(tireLabours, l => l.price), true)} <span
                            className="currency">{BaseCountry().currency}</span></div>
                    </div>
                </div>
            );
        }

        const Tires = () => {
            const {tires} = this.props;

            if(!tires)  return null

            return (
                <div className="spec-item">
                    <div className="spec-item-title">{t('pages.form_steps.overview.request_specification.tires_title')}</div>

                    <div className="spec-item-header">
                        <div className="col-sm-1">{t('pages.form_steps.tires.label.axel')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.tires.label.brand')}</div>
                        <div className="col-sm-3">{t('pages.form_steps.tires.label.profile')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.tires.label.season')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.tires.label.dimensions')}</div>
                        <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.price')}</div>
                    </div>
                    <div className="spec-item-list">
                        {tires[TIRES_AXEL_FRONT] &&
                        <div className="spec-item-list__item">
                            <div className="col-sm-1">{t('pages.form_steps.tires.axel.front')}</div>
                            <div className="col-sm-2">{tires[TIRES_AXEL_FRONT].brand}</div>
                            <div className="col-sm-3">{tires[TIRES_AXEL_FRONT].profile}</div>
                            <div className="col-sm-2">{t('pages.form_steps.tires.season.' + tires[TIRES_AXEL_FRONT].season)}</div>
                            <div className="col-sm-2">{tires[TIRES_AXEL_FRONT].full_axel}</div>
                            <div className="col-sm-2">{fixedFloat(tires[TIRES_AXEL_FRONT].price)} <span className="currency">{BaseCountry().currency}</span></div>
                        </div>
                        }
                        {tires[TIRES_AXEL_REAR] &&
                        <div className="spec-item-list__item">
                            <div className="col-sm-1">{t('pages.form_steps.tires.axel.rear')}</div>
                            <div className="col-sm-2">{tires[TIRES_AXEL_REAR].brand}</div>
                            <div className="col-sm-3">{tires[TIRES_AXEL_REAR].profile}</div>
                            <div className="col-sm-2">{t('pages.form_steps.tires.season.' + tires[TIRES_AXEL_REAR].season)}</div>
                            <div className="col-sm-2">{tires[TIRES_AXEL_REAR].full_axel}</div>
                            <div className="col-sm-2">{fixedFloat(tires[TIRES_AXEL_REAR].price)} <span className="currency">{BaseCountry().currency}</span></div>
                        </div>
                        }
                    </div>

                    <div className="spec-item-total">
                        <div className="col-sm-6">{t('global.total')}:</div>
                        <div className="col-sm-6">{fixedFloat(tires.price_tires, true)} <span
                            className="currency">{BaseCountry().currency}</span></div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>

                <div className="request-specification">
                    <h4><i className="icon-list"/> {t('pages.form_steps.overview.request_specification.title')}</h4>

                    <Parts/>

                    <Oil/>

                    <Works/>

                    <CustomLabours/>

                    <TireLabours/>

                    <Tires/>

                    <Total/>

                </div>

            </React.Fragment>
        )
    }
}

export default translate('translations')(RequestSpecification);
