import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Confirm from "../../../../components/shared/Confirm/Confirm";
import {toast} from "react-toastify";
import Toast from "../../../../components/shared/Toast/Toast";
import {parseResponse} from "../../../../utils/common";

class LabourDetails extends Component {

    state = {
        removeConfirm: null,
        removing: false,
    }

    confirmRemove = () => {
        const {removeConfirm} = this.state;
        const {onRemove} = this.props;

        this.setState({removing: true})

        onRemove(removeConfirm)
            .then(() => this.setState({removeConfirm: null}))
            .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
            .finally(() => this.setState({removing: false}))
    }

    render() {
        const {removeConfirm, removing} = this.state;
        const {t, labour, remove, siblings} = this.props;

        const WorkDetails = ({work}) => {
            return <li className="labour-detail-work">
                {work.name}

                <ul>
                    {work.parts.filter(p => p.selected).map((part, key) => <PartDetails key={key} part={part}/>)}
                </ul>
            </li>
        }

        const PartDetails = ({part}) => {

            let version = part.versions.find(v => v.selected);

            let sets = [];
            if (version) {
                sets = version.set.filter(s => s.selected);
            }

            return sets.map((set, key) => <li key={key} className="labour-detail-part">{set.name}</li>)
        }

        const showWorks = (works) => {
            return works.filter(w => w.selected).length > 1;
        }

        return (
            <React.Fragment>

                <div className="labour-detail">
                    <div className="labour-detail-header">
                        <div className="labour-detail-header__title">{labour.name}</div>
                        <div className="labour-detail-header__icons">
                            {remove !== false &&
                            <span className="remove" onClick={() => this.setState({removeConfirm: labour})}><i
                                className="icon-delete"/> {t('global.remove')}</span>}
                        </div>
                    </div>
                    {showWorks(labour.works) &&
                    <div className="labour-detail-content">
                        <ul>
                            {labour.works.filter(w => w.selected).map((work, key) =>
                                <WorkDetails key={key} work={work}/>)
                            }
                        </ul>
                    </div>
                    }
                </div>

                {removeConfirm &&
                <Confirm
                    title={t('pages.form_steps.overview.labour_remove_title')}
                    acceptText={t('global.remove')}
                    visible={!!removeConfirm}
                    accept={this.confirmRemove}
                    loading={removing}
                    cancel={() => this.setState({removeConfirm: null})}
                >
                    <div className={"mv-30"}>
                        {labour.name}

                        {siblings.map((sibling, key) => (
                            <div key={key}>{sibling.name}</div>
                        ))}
                    </div>
                </Confirm>
                }

            </React.Fragment>
        )
    }
}

export default translate('translations')(LabourDetails);
