/* eslint-disable */
import React from 'react'
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR, TIRES_SEASON_ALL_SEASON, TIRES_SEASON_NORDIC,
    TIRES_SEASON_SUMMER,
    TIRES_SEASON_WINTER
} from "../../../store/consts/tires.constants";

export default {
    translations: {
        links: {
            home: 'Home',
            services: 'Services',
            garage: 'Garage',
        },
        titles: {
            login: 'Sign in',
            password_reset: 'Password reset',
            password_reset_new: 'New password activation',
            pick_garage: 'Choose garage',

            home: 'Fleet Access',

            settings: 'Settings',
            user_profile: 'User Profile',

            requests: 'Requests',
            request_details: 'Request details',

            form_steps: {
                car: 'Vehicle data',
                official_service: 'Recommended services',
                tires: 'Tires', //TODO: Translate
                labours: 'Additional services',
                overview: 'Overview',
                review: 'Your request is under review',
            }
        },
        global: {
            alt: {
                'request-send-success-icon': 'Success icon',
            },
            tabs: {
                pending: 'Pending',
                other: 'Other',
            },
            or: 'or',
            cancel: 'Cancel',
            back: 'Back',
            more: 'more',
            currency: 'EUR',
            tax: 'Tax',
            total: 'Total',
            total_with_vat: 'Total incl. VAT',
            discount: 'Discount',
            files: 'Photos',
            service: 'Service',
            to: 'to',
            part_number: 'Part number',
            km: 'km',
            loading: 'Loading ...',
            garage_reinitialize: 'Switching garage...',
            yes: 'Yes',
            no: 'No',
            vat: 'VAT',
            upload: 'Upload', //TODO:Translate
            car: {
                make: 'Make',
                range: 'Range',
                type: 'Type',
                go_back: 'Edit',
                year: 'Year of production',
                licence_plate: 'Numberplate',
                update_info: 'Update car information',
                edit: 'Bearbeiten',
                mileage: 'Mileage',
            },
            official_service: {
                title: 'RECOMMENDATION FOR YOUR CAR',
                description:
                    "Thanks to the data you have provided us together with the manufacturer's specifications for {{carBrand}}, we have set up a personalised service for your car: ",
                includes: 'INCLUDES',
            },
            preselected_service: {
                title: 'Selected services: ',
            },
            additional_service: {
                open: 'Additional service list',
                title: 'Maintenance',
                description: 'Pick additional works to refine the results.',
                uncheck_all: 'Uncheck all',
                save: 'Save',
            },
            msg_title: {
                error: 'Error message',
                info: 'Info message',
            },
            save: 'Save',
            update: 'Update',
            edit: 'Edit',
            remove: 'Remove',
            undo: 'Undo',
            undo_deleted: '{{num}} has been deleted',
            you_sure: 'Are you sure?',
            months: {
                0: 'January',
                1: 'February',
                2: 'March',
                3: 'April',
                4: 'May',
                5: 'June',
                6: 'July',
                7: 'August',
                8: 'September',
                9: 'October',
                10: 'November',
                11: 'December',
            },
            agreement: 'Accept the',
            terms_and_conditions: 'terms and conditions',
            privacy_policy: 'Privacy policy',
            no_data: 'No data',
            optional: 'optional',
            dont_know_lp: 'Don\'t know your plate number?',
            form_leave_prompt: 'Are you sure you wont to leave, you can lose your data', //TODO: Translate
        },
        form: {
            placeholder: {
                contract_number: 'Contract number',
                make: 'Select make',
                range: 'Select range',
                type: 'Select type',
                gear: 'Select gear',
                first_registration: '2019',
                qual_md_list: 'Select car features',
                qual_lt_list: 'Select vehicle body',
                zip: 'Select zip',
                username: 'Phone / E-Mail / Username',
                username2: 'Enter username',
                year: '2019',
                first_name: 'First Name',
                last_name: 'Last Name',
                mechanic_type: 'Type',
                email: 'Email',
                phone: 'Phone',
                password: 'Password',
                password_confirmation: 'Password Confirm',
                city: 'City',
                color: 'Color',
                canton: 'ZH',
                plate_number: '18 341',
                default: {
                    select: 'Please select',
                },
                user_activity: 'Status',
                date: 'Pick a date',
                morning: 'Morning',
                afternoon: 'Afternoon',
                car_number: {
                    vin_swiss: 'Swiss Type Nr or VIN',
                    vin_kba: 'KBA number or VIN',
                    vin: 'VIN',
                    swiss: 'Swiss Type Nr',
                    kba: 'KBA number',
                    default: 'Car number', // za ovo nisam siguran
                },
                user_type: 'Account Type',
                mileage: 'Mileage',
                leasing: 'Fleet owner',
            },
            label: {
                contract_number: 'Contract number',
                licence_plate: 'Numberplate',
                make: 'Make',
                range: 'Range',
                type: 'Type',
                gear: 'Gear',
                first_registration: 'Year of registration',
                qual_md_list: 'Car features',
                qual_lt_list: 'Vehicle body',
                username: 'Phone / E-Mail / Username',
                username2: 'Username',
                year: 'Year of registration',
                first_name: 'First name',
                last_name: 'Last name',
                phone: 'Phone:',
                email: 'Email',
                password: 'Password',
                password_confirmation: 'Password Confirm',
                date: 'Date',
                availability: 'Availability',
                bookFor: 'Book for',
                book: 'Request',
                city: 'City',
                color: 'Color',
                car_number: {
                    vin_swiss: 'Swiss Type Nr or VIN',
                    vin_kba: 'KBA number or VIN',
                    vin: 'VIN',
                    swiss: 'Swiss Type Nr',
                    kba: 'KBA number',
                    default: 'Car number', // za ovo nisam siguran
                },
                user_type: 'Type',
                mileage: 'Mileage',
                leasing: 'Fleet owner',
            },
            button: {
                next: 'Next',
                confirm: 'Confirm',
                submit: 'Continue',
            },
            select: {
                no_result: 'No result found.',
            },
        },
        validations: {
            label: {
                contract_number: 'Contract number',
                make: 'make',
                range: 'range',
                type: 'type',
                first_registration: 'year of registration',
                gear: 'gear',
                qual_md_list: 'car features',
                qual_lt_list: 'Vehicle body',
                car_number: 'Car number',
                avatar: 'Avatar',
                username: 'Phone / E-Mail / Username ',
                username2: 'Username',
                firstname: 'first name',
                lastname: 'last name',
                phone: 'phone',
                email: 'email',
                password: 'password',
                password_match: 'Passwords must match',
                user_type: 'account type',

                zip_not_found: 'No cities with ZIP code {{name}}',
                zip_not_valid: 'Please select valid ZIP code.',
                zip_required: 'ZIP is required.',
                zip_must_be_number: 'Must be a number.',
                leasing: 'Fleet owner',
                mileage: 'Mileage',
            },
            default: {
                mixed: {
                    required: '${label} required',
                    number: 'You have to agree with our ${label}',
                },
                string: {
                    email: 'Please fill in a valid email',
                    min: 'Value too short',
                    max: 'Value too long',
                    test: 'You have to agree with our ${label}',
                    number: 'You have to agree with our ${label}',
                },
                boolean: {
                    required: 'You have to agree with our ${label}',
                    number: 'You have to agree with our ${label}',
                },
            },
            licence_plate: {
                canton: {
                    valid: 'Valid canton',
                    nonValid: 'Invalid canton',
                },
                number: {
                    valid: 'Valid number',
                    nonValid: 'Invalid number',
                },
            },
            car_number: {
                invalid_format: 'Invalid format for SWISS',
            },
        },
        header: {
            profile_settings: 'Profile settings',
            reporting: 'Reporting',
            switch_garage: 'Switch garage',
            settings: 'Garage settings',
            logout: 'Logout',
            terms_and_conditions: 'Terms and conditions',
            privacy_policy: 'Privacy policy',
            requests: 'Requests',
        },
        confirm_popup: {
            ok: 'OK',
            cancel: 'Cancel',
        },
        user: {
            activity: {
                active: 'Active',
                inactive: 'Inactive',
            },
            roles: {
                manager: 'Manager',
                mechanic: 'Mechanic',
                mechanic_advanced: 'Mechanic Advanced',
                supervisor: 'Supervisor',
            },
        },
        pages: {
            home: {
                title: 'Home',
            },
            login: {
                title: 'Sign in',
                submit: 'Sign in',
                forget_password: 'Forgot password?',
            },
            password_reset: {
                title: 'Password reset',
                tip: 'Share your email with us, and we will send you instructions how to reset the password.',
                submit: 'Reset password',
            },
            password_reset_new: {
                title: 'New password activation',
                tip: 'Enter your new password in the following fields.',
                submit: 'Reset password',
            },
            pick_garage: {
                title: 'Choose garage',
                tip: 'Please choose garage you want to sign in as.',
            },
            settings: {
                title: 'Settings',
                section_tabs: {
                    change_details: 'Change details',
                    phones: 'Contact number (displayed to customers)',
                    communication: 'Notifications',
                },
                section_form: {
                    label: {
                        address: 'Address:',
                        web: 'Web:',
                        zip_id: 'Zip:',
                        logo: 'Logo:',
                        notification_phone: 'Phone:',
                        notification_email: 'Email:',
                    },
                    placeholder: {
                        address: 'Address:',
                        web: 'Web:',
                        zip_id: 'Zip:',
                        logo: 'Logo:',
                        email: 'Enter email',
                        phone: 'Enter phone',
                    },
                    logo_change: 'Change',
                    logo_upload: 'Upload logo',
                    avatar_upload: 'Upload avatar',
                    avatar_change: 'Change avatar',
                    submit_user_details: 'Save',
                    notification_detail: 'List all associated recipients in order to get notifications.',
                    phones_detail: 'List all phones to show to the client.',
                    add_new_phone: 'Add new phone',

                    file_size: 'File too large',
                    file_format: 'Unsupported format',
                    one_of_required: 'Phone or email is required',

                    communication: {
                        nexmo_multiple: 'SMS',
                        mail: 'E-mail',
                        push_notification: 'Push',
                    },
                    communication_required: 'Minimum one notification channel is required',
                },
                section_popup: {
                    header_remove: 'Are you sure you want to remove recipient?',
                    header_add: 'Add new recipient',
                    header_update: 'Update recipient',
                    remove: 'Remove',
                },
            },
            user_profile: {
                title: 'User Profile',
                section_tabs: {
                    default_language: 'Default language',
                    change_password: 'Password change',
                    change_details: 'Garage details',
                },
                section_form: {
                    label: {
                        current_password: 'Current password:',
                        new_password: 'New password:',
                        repeat_password: 'Confirm new password:',
                        notification_phone: 'Phone:',
                        notification_email: 'E-Mail:',
                        first_name: 'First name:',
                        last_name: 'Last name:',
                        email: 'E-Mail:',
                        phone: 'Phone:',
                        username: 'Username:',
                    },
                    placeholder: {
                        current_password: 'Enter current password',
                        new_password: 'Enter new password',
                        repeat_password: 'Repeat new password',
                        notification_email: 'Enter new email',
                        notification_phone: 'Enter new phone',
                        first_name: 'Enter first name',
                        last_name: 'Enter last name',
                        email: 'Enter E-Mail',
                        phone: 'Enter phone',
                        username: 'Username',
                    },
                    avatar: {
                        change: 'Change',
                        crop: {
                            select: 'Select image',
                            zoom: 'Zoom',
                            rotate: 'Rotate',
                            save: 'Save crop image',
                            cancel: 'Cancel',
                            title: 'Crop image',
                        },
                    },
                    submit_new_password: 'Save',
                    submit_user_details: 'Save',

                    file_size: 'File too big ',
                    file_format: 'Unsupported format',
                },
            },

            form_steps: {
                car: {
                    method_title_lp: 'Enter car info',
                    method_title_swiss: 'Find car by SWISS',
                    method_title_multiple: 'Choose your cars color',
                    method_title_manual: 'Please enter car info manually',
                    method_title_manual_2: 'We found your car',

                    sourced_success: 'We successfully sourced your car information.',

                    car_number: {
                        vin_swiss: "Don't know the Swiss Type Nr or VIN?",
                        vin_kba: "Don't know the KBA number or VIN?",
                        vin: "Don't know the VIN?",
                        swiss: "Don't know the Swiss Type Nr?",
                        kba: "Don't know the KBA number?",
                        default: "Don't know the car number?",
                    },
                    swiss: {
                        manual: 'Find car by entering details manually',
                    },
                },
                official_service: {
                    title: 'Select service package',
                    total_official: 'Total incl. VAT',
                    select_other_services: 'Select other services:',
                    submit: 'Save',
                    no_recommended: 'No official service recommendation for this vehicle.',
                    or_more: 'or more',
                    info: {
                        ok: 'OK',
                    },
                    service_interval_title: 'Service at ',
                    show_more: 'more',
                    show_less: 'less',
                    included_in_official: 'Included in the service',
                },
                labours: {
                    title: 'Additional services',
                    search: {
                        placeholder: 'Search services',
                    },
                    submit: 'Save',
                    no_result: 'No services found. Please check Your spelling.',
                    load_more: 'Load more',
                    not_available: 'Labour not available',
                },
                tires: {
                    title: 'Tires', //TODO: Translate

                    axel: {
                        [TIRES_AXEL_FRONT]: 'Front',
                        [TIRES_AXEL_REAR]: 'Rear',
                        front: 'Front',
                        rear: 'Rear',
                        both: 'F + R',
                    },
                    season: {
                        [TIRES_SEASON_SUMMER]: 'Summer',
                        [TIRES_SEASON_WINTER]: 'Winter',
                        [TIRES_SEASON_ALL_SEASON]: 'All Season',
                        [TIRES_SEASON_NORDIC]: 'Nordic',
                    },

                    filter_title_both: 'Front + rear tires',
                    filter_title_front: 'Front tires',
                    filter_title_rear: 'Rear tires',

                    filter_index_all: 'All index',

                    package_name: 'Tires change',

                    ean: 'EAN',
                    sap: 'SAP',

                    label: {
                        axel: 'Axel',
                        wide: 'Wide',
                        depth: 'Depth',
                        diameter: 'Diameter',

                        season: 'Season',
                        index: 'Index',
                        brand: 'Brand',
                        profile: 'Profile',
                        dimensions: 'Dimensions',
                        run_flat: 'Run flat',

                        front_axel: 'Front axle',
                        rear_axel: 'Rear axle',

                        same_dimension: 'Same dimension',
                    },

                    labours: { // TODO: Translate
                        new_tires: 'New Tires', //TODO: Translate

                        rim_size: {
                            title: 'Select rim size and axels for {{labour}}',
                            select: 'Rim size',
                        },
                    },

                    process: {
                        title: 'Select tires', // TODO: Translate
                    },

                    header: {
                        brand: 'Name',
                        index: 'Index',
                        price: 'Price',
                    },

                    no_tires: 'No tires for this criteria',
                },
                overview: {
                    title: 'Overview',
                    show_request_spec: 'Show request specification',
                    hide_request_spec: 'Hide request specification',
                    labour_remove_title: 'Are You sure You want to remove following services?',
                    request_specification: {
                        title: 'Request specification',
                        parts_title: 'Parts',
                        tires_title: 'Tires',
                        tire_labours_title: 'Tire Labours',
                        serial_number: 'Part number',
                        part_name: 'Parts',
                        quantity: 'Quantity',
                        oil_title: 'Oil',
                        price: 'Price',
                        parts_discount: 'Discount on parts',
                        brakes_discount: 'Discount on brakes',
                        works_title: 'Working hours',
                        custom_labours: 'Custom labours',
                        works_name: 'Service',
                        time: 'Time',
                        calc_time: 'Calc. time',
                        hourly_price: 'EUR/H',
                        recommended_oil: 'Manufacturers recommendation',

                        tire_labours: {
                            front_size: 'Front size',
                            front_price: 'Front price',
                            rear_size: 'Rear size',
                            rear_price: 'Rear price',
                            run_flat_add: 'Run flat add',
                        },
                    },
                    agree_with_calculation: 'I agree that I checked the prices of the works and parts, and I am aware of the calculations, and I take full responsibility of the calculated offer.',
                },
                review: {
                    in_progress: {
                        title: 'Your request is under review!',
                        subtitle: 'Approval may take a few minutes...',
                        desc: 'Alternatively, you may proceed with other requests and we will notify you as soon as this request has been answered to. You will find the notification and may check request status in request list at all times.',
                        cancel: 'Cancel request',
                        all_requests: 'All requests',
                        cancel_request_title: 'Are You sure You want to cancel following request?',
                    },
                    accepted: {
                        title: 'Request accepted!',
                        subtitle: 'Your request for {{car}} has been accepted.',
                        desc: 'Request accepted description',
                        cancel: 'Cancel request',
                        status: 'Approved',
                        see_details: 'See request details',
                        print: 'Print PDF / Export to...',
                    },
                    rejected: {
                        title: 'Request denied!',
                        subtitle: 'Your request for {{car}} has been denied.',
                        reason: 'Reason:',
                        status: 'Denied',
                        archive: 'Archive request',
                        edit: 'Edit request',
                        edit_confirm: 'Are you sure You want to edit this request? It’s a non-reversible action.',
                        archive_confirm: 'Are you sure You want to archive this request? It’s a non-reversible action.',
                    },
                }
            },
            requests: {
                title: 'All requests',
                filter_label: 'Filter by status',
                no_result: 'There is no results',
                pending_status: 'Pending',
                approved_status: 'Approved',
                denied_status: 'Denied',
                archived_status: 'Archived',
                all: 'All',
                table: {
                    status: 'Status',
                    leasing_provider: 'Fleet owner',
                    car_info: 'Car info',
                    request_details: 'Request details',
                    date: 'Date',
                    pdf: 'PDF',
                    status_1: 'Pending',
                    status_4: 'Canceled',
                    status_2: 'Approved',
                    status_3: 'Denied',
                },
            },

            request_details: {
                title: 'Request details',
                upload_invoice_label: 'Upload invoice',
                export_xml: 'Export XML',
                export_pdf: 'Download PDF',
                edit: 'Edit request',
                edit_desc: 'Are you sure you want to edit this request? It’s a non-reversible action.',
                cancel: 'Cancel request',
                cancel_desc: 'Are you sure you want to cancel this request? It’s a non-reversible action.',
                archive: 'Archive request',
                archive_desc: 'Are you sure you want to archive this request? It’s a non-reversible action.',
                statuses: {
                    1: 'In Progress',
                    2: 'Approved',
                    3: 'Rejected',
                    4: 'Canceled',
                },
                request_id: 'Approval number:',
                date: 'Date:',
                status: 'Status:',
                archived: 'Archived',
                denial_reason: 'Reason for denial',
                request_overview: 'Request overview',
                add_new_service: 'Edit the request',
                car_details: 'Car details',
                car_model: 'Model',
                car_range: 'Range',
                car_type: 'Type',
                car_vin_swiss: 'Vin/Swiss type',
                car_mileage: 'Mileage',
                car_first_reg: 'First registration',
                car_leasing: 'Fleet owner',

                upload_invoice: { // TODO: Translate
                    file_type: 'Allowed file types are {{format}}',
                    file_size: 'Maximum file size {{size}}',
                },
                invoice: { // TODO: Translate
                    title: 'Invoice',
                    download_invoice: 'Invoice',
                    pending: ' (in review)',
                    uploaded: 'Uploaded',
                },
                drag_and_drop: {
                    title: 'Drag and drop',
                    or: 'or',
                    upload_from: 'Upload from Your device',
                }
            },
        },
        labours: {
            add: 'Add',
            edit: 'Edit',
            change: 'Change',
            add_edit: 'Add / Edit',
            available_works: 'Available works',
            available_parts: 'Available parts',
            more: 'more',
            details: 'Details',
            remove: 'Remove',
            removing: 'removing...',
            missing_work: 'Works are missing',
            missing_parts: 'To add missing parts go to Details!',
            total: 'Total',
            discount_on_parts: 'Discount on parts',
            discount_on_brakes: 'Discount on brakes',
            total_all: 'Total incl VAT & discount',
            price_fixed: 'Fixed price',

            confirm_edit: {
                title: 'Are you sure?',
                subtitle: 'Adding new labour can overwrite some changes you have made in previous labours',
            },

            labour: {
                works: {
                    title: 'Available works',
                    add_more: '+ Add more work',
                    list: {
                        title: 'Works list',
                    },
                    section_form: {
                        label: {
                            name: 'Name',
                            description: 'Description',
                            duration: 'Duration',
                        },
                        placeholder: {
                            name: 'Enter work name',
                            description: 'Description',
                            duration: 'Enter work duration',
                        },
                    },
                    edit: {
                        title: 'Edit work',
                    },
                    add: {
                        title: 'Add work',
                    },
                },
                parts: {
                    title: 'Parts',
                    add_more: '+ Add more parts',
                    versions: {
                        title: 'Parts',
                    },
                    set: {
                        title: 'Edit part set',
                        section_form: {
                            label: {
                                name: 'Name',
                                price: 'Price',
                                amounts: 'Amounts',
                            },
                            placeholder: {
                                name: 'Enter part set name',
                                price: 'Enter part set price',
                                amounts: 'Enter amounts of part set',
                            },
                        },
                    },
                    add: {
                        title: 'Add new part',
                    },
                },
            },
        },
        toast: {
            success: 'Thank you',
            error: 'Oops!',
            info: 'Info',
            warning: 'Warning',
        },
        language: {
            en: 'English',
            de: 'German',
            fr: 'French',
        },
        form_steps: {
            1: 'Car data',
            2: 'Service according to the manufacturers data',
            3: 'Tires', //TODO: Translate
            4: 'Additional works',
            5: 'Overview of the offer',
            6: 'Check the works and validate the price',
        },
        footer: {
            copyright: 'Copyright by Stratos Technologies AG 2021',
            terms: 'Terms and conditions',
            privacy: 'Privacy policy',
        },
    },
}
