import {createSelector} from 'reselect';
import {
    TIRES_AXEL_FRONT, TIRES_AXEL_REAR,
    TIRES_SORT_ASC,
    TIRES_STEP_AXEL, TIRES_STEP_LIST,
} from "../consts/tires.constants";
import {toBool} from "../../utils/common";
import {cloneDeep, sortBy, uniq} from "lodash";
import i18next from "i18next";
import tiresProcessModel from "../models/tiresProcess";
import {selectTireLaboursSelected} from "./tireLaboursPredefined";

const selectTiresState = state => state.tires;
const selectTireLaboursPredefined = state => state.tireLaboursPredefined;

const selectAreBothAxelSelected = createSelector(
    [selectTiresState],
    (tires) => {
        return tires.axel.front.selected && tires.axel.rear.selected;
    }
);

const selectAreBothAxelSameDimension = createSelector(
    [selectTiresState, selectAreBothAxelSelected],
    (tires, bothAxelSelected) => {
        return bothAxelSelected && tires.axel.front.wide === tires.axel.rear.wide && tires.axel.front.depth === tires.axel.rear.depth && tires.axel.front.diameter === tires.axel.rear.diameter;
    }
);

const selectSelectedTires = createSelector(
    [selectTiresState],
    (tires) => {

        let selected = [];

        ['front', 'rear'].forEach((axel) => {
            if (tires.axel[axel].selected && tires.axel[axel].tire) {
                selected.push(tires.axel[axel].tire.ean)
            }
        })

        return selected;
    }
);

const selectTiresList = createSelector(
    [selectTiresState, selectSelectedTires],
    (tires, selectedTires) => {

        let list = tires.list;

        list = list.map(tire => ({
            ...tire,
            selected: selectedTires.indexOf(tire.ean) !== -1,
        }));

        list.sort((a, b) => {
            let x = a[tires.sort.column];
            let y = b[tires.sort.column];

            if(tires.sort.column === 'price'){
                return tires.sort.order === TIRES_SORT_ASC ? x - y : y - x;
            }

            if(tires.sort.column === 'brand'){
                x = `${a[tires.sort.column]} ${a.profile}`;
                y = `${b[tires.sort.column]} ${b.profile}`;
            }

            if(tires.sort.order === TIRES_SORT_ASC){
                return x.toString().localeCompare(y)
            }

            return y.toString().localeCompare(x)
        });

        return tires.index ? list.filter(tire => tire.index.toLowerCase().includes((tires.index).toLowerCase())) : list;
    }
);

export const selectTiresValidateSteps = createSelector(
    [selectTiresState],
    (tires) => {

        let stepAxel = true, stepList = true;
        ['front', 'rear'].forEach((axel) => {
            if (tires.axel[axel].selected && toBool(tires.axel[axel].wide && tires.axel[axel].depth && tires.axel[axel].diameter) === false) {
                stepAxel = false;
            }

            if (tires.axel[axel].selected && toBool(tires.axel[axel].tire) === false) {
                stepList = false;
            }
        });

        return {
            [TIRES_STEP_AXEL]: stepAxel,
            [TIRES_STEP_LIST]: stepList,
        }
    }
)

export const selectTiresFormatSelected = createSelector(
    [selectTiresState],
    (tires) => {

        let model = cloneDeep(tiresProcessModel);

        if(tires.axel.front.selected && tires.axel.front.tire){
            model.tires[TIRES_AXEL_FRONT] = tires.axel.front.tire;
        }

        if(tires.axel.rear.selected && tires.axel.rear.tire){
            model.tires[TIRES_AXEL_REAR] = tires.axel.rear.tire;
        }

        return model.tires;
    }
)

const selectIndexList = createSelector(
    [selectTiresState],
    (tires) => {

        let list = tires.list;

        let indexList = sortBy(uniq(list.map(tire => tire.index)).map(index => {
                return {
                    id: index,
                    name: index,
                }
            }),
            [function(o) { return parseInt(o.id); }]);

        indexList.unshift({id: 0, name: i18next.t('pages.form_steps.tires.filter_index_all')});

        return indexList;
    }
)

const selectLabours = createSelector(
    [selectTiresState, selectTireLaboursPredefined],
    (tires, tireLaboursPredefined) => {

        return tireLaboursPredefined.filter(l => (l.in_new_tires || l.in_tire_process) && l.checked);
    }
)

const selectAxel = createSelector(
    [selectTiresState, selectTireLaboursSelected],
    (tires, tireLaboursSelected) => {

        let frontRimSize = null;
        let rearRimSize = null;

        if(!tires.axel.front.wide && !tires.axel.front.depth && !tires.axel.front.diameter){ // TODO: Temp only for presentation
            tires.axel.front.wide = 175;
            tires.axel.front.depth = 65;
            tires.axel.front.diameter = 14;
        }

        if(!tires.axel.rear.wide && !tires.axel.rear.depth && !tires.axel.rear.diameter){ // TODO: Temp only for presentation
            tires.axel.rear.wide = 175;
            tires.axel.rear.depth = 65;
            tires.axel.rear.diameter = 14;
        }

        tireLaboursSelected.forEach(labour => {
            if(labour.axel.front.rim_size){
                frontRimSize = labour.axel.front.rim_size;
            }

            if(labour.axel.rear.rim_size){
                rearRimSize = labour.axel.rear.rim_size;
            }
        })

        if(frontRimSize && !tires.axel.front.diameter && tires.axel.front.diameter_list.find(diameter => diameter.name === frontRimSize)){
            tires.axel.front.diameter = frontRimSize;
        }

        if(rearRimSize && !tires.axel.rear.diameter && tires.axel.rear.diameter_list.find(diameter => diameter.name === rearRimSize)){
            tires.axel.rear.diameter = rearRimSize;
        }

        return tires.axel;
    }
)

export const selectTires = createSelector(
    [selectTiresState, selectTiresList, selectAxel, selectAreBothAxelSelected, selectAreBothAxelSameDimension, selectIndexList, selectLabours],
    (tires, tiresList, axel, bothAxelSelected, bothAxelSameDimension, indexList, labours) => {

        return {
            ...tires,

            axel: axel,

            list: tiresList,

            list_front: (!bothAxelSameDimension && bothAxelSelected) ? tiresList.filter(tire => tire.wide === tires.axel.front.wide && tire.depth === tires.axel.front.depth && tire.diameter === tires.axel.front.diameter) : [],
            list_rear: (!bothAxelSameDimension && bothAxelSelected) ? tiresList.filter(tire => tire.wide === tires.axel.rear.wide && tire.depth === tires.axel.rear.depth && tire.diameter === tires.axel.rear.diameter) : [],

            index_list: indexList,

            labours: labours,

            bothAxelSelected: bothAxelSelected,
            bothAxelSameDimension: bothAxelSameDimension
        }
    }
)
