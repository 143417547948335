import React, {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {HasOfferToken} from 'routes/middleware/HasOfferToken'
import Header from "components/layout/Header/Header";
import "./OfficialServiceStepContainer.scss";
import {__clone, parseResponse, toBool} from "utils/common";
import {uniqBy} from "lodash";
import {toast} from "react-toastify";
import Toast from "components/shared/Toast/Toast";
import {
    officialServicesFetch,
    officialServicesSet,
    officialServicesUpdate
} from "store/actions/officialServices.actions";
import ListLoader from "components/shared/ListLoader/ListLoader";
import {
    OFFICIAL_SERVICE_NEXT,
    OFFICIAL_SERVICE_PREV,
    OFFICIAL_SERVICE_SELECTED
} from "store/consts/officialServices.constants";
import ItemRecommended from "./Items/ItemRecommended";
import ItemAdditional from "./Items/ItemAdditional";
import ItemMain from "./Items/ItemMain";
import CarStepMiddleware from "../../../routes/middleware/FormSteps/CarStepMiddleware";
import {changeStepData, setStepNext, setStepPrev} from "../../../store/actions/step.actions";
import {laboursUpdate} from "../../../store/actions/labours.actions";
import FormStepCounter from "../../../components/FormStepCounter/FormStepCounter";
import FormStepSubmit from "../../../components/FormStepSubmit/FormStepSubmit";
import FormBeforeLeavePrompt from "../../../components/FormBeforeLeavePrompt/FormBeforeLeavePrompt";
import {FORM_STEP_OFFICIAL_SERVICE} from "../../../store/consts/step.constants";

class OfficialServiceStepContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mileage: props.car.mileage,
            creating: false,
            loading: false,
        }
    }

    componentDidMount() {
        const {car, dispatch, officialServices: {main, additional, mileage}} = this.props;

        dispatch(changeStepData(FORM_STEP_OFFICIAL_SERVICE));

        if ((this.recommended().length === 0 && main.length === 0 && additional.length === 0) || mileage !== car.mileage) {
            this.setState({loading: true})

            dispatch(officialServicesFetch(car.mileage))
                .then((response) => {
                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    this.setState({loading: false})
                })
        }
    }

    recommended = () => {
        const {officialServices: {recommended}} = this.props;

        return [OFFICIAL_SERVICE_PREV, OFFICIAL_SERVICE_NEXT].indexOf(recommended.selected) !== -1 ? recommended[OFFICIAL_SERVICE_SELECTED[recommended.selected]] : [];
    }

    handleChangeAdditional = (e) => {
        const {dispatch, officialServices: {additional}} = this.props;

        let labour = additional.find(l => l.itemMpId === parseInt(e.target.value));

        if (labour) {
            labour.checked = e.target.checked;
            dispatch(officialServicesUpdate(labour, 'additional'));
        }
    }

    handleChangeMain = (e) => {
        const {dispatch, officialServices: {main}} = this.props;

        let labour = main.find(l => l.itemMpId === parseInt(e.target.value));

        if (labour) {
            labour.checked = e.target.checked;
            dispatch(officialServicesUpdate(labour, 'main'));
        }
    }

    handleChangeRecommended = (e) => {
        const {dispatch, officialServices: {recommended, main, additional, mileage, exist}} = this.props;

        let selected = e.target.checked ? parseInt(e.target.value) : null;

        let getRecommendedSelected = (service, selected) => {

            if (!recommended[OFFICIAL_SERVICE_SELECTED[selected]]) {
                return false;
            }

            return toBool(recommended[OFFICIAL_SERVICE_SELECTED[selected]].find(l => l.itemMpId === service.itemMpId));
        }

        dispatch(officialServicesSet({
                ...recommended,
                next: __clone(recommended.next).map(service => {
                    return {...service, checked: selected === OFFICIAL_SERVICE_NEXT}
                }),
                prev: __clone(recommended.prev).map(service => {
                    return {...service, checked: selected === OFFICIAL_SERVICE_PREV}
                }),
                selected: selected,
            },
            __clone(main).map(service => {
                return {
                    ...service,
                    checked: getRecommendedSelected(service, selected),
                    inOfficial: getRecommendedSelected(service, selected),
                }
            }),
            __clone(additional).map(service => {
                return {
                    ...service,
                    checked: getRecommendedSelected(service, selected),
                    inOfficial: getRecommendedSelected(service, selected),
                }
            }), mileage, exist))
    }

    getSelectedServices = () => {
        const {officialServices: {main, additional}} = this.props;

        return uniqBy(
            this.recommended().filter(labour => labour.checked === true)
                .concat(main.filter(labour => labour.checked === true))
                .concat(additional.filter(labour => labour.checked === true))
            , (service) => service.itemMpId);
    }

    submit = () => {
        const {officialServices: {recommended}} = this.props;
        const {dispatch} = this.props;

        let services = this.getSelectedServices();

        this.setState({creating: true});

        dispatch(laboursUpdate(services, recommended))
            .then((response) => {

                dispatch(setStepNext())

            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({creating: false}))
    }

    back = () => this.props.dispatch(setStepPrev())

    render() {
        const {t, title, officialServices: {recommended, main, additional}} = this.props;
        const {creating, loading} = this.state;

        return (
            <React.Fragment>

                <FormBeforeLeavePrompt />

                <Header/>

                <div className="container container--has-submit-container">

                    <div className="row">
                        <div className="col-sm-1">
                            <FormStepCounter short={true}/>
                        </div>
                        <div className="col-sm-11">
                            {loading ? <ListLoader/> :
                                <div className="official-services-container">

                                    <h2>{t(title)}</h2>

                                    <div className="row">
                                        <div className="col-xs-12">
                                            {toBool(recommended.prev.length) &&
                                            <ItemRecommended
                                                recommended={recommended}
                                                value={OFFICIAL_SERVICE_PREV}
                                                change={this.handleChangeRecommended}
                                            />
                                            }
                                        </div>
                                        <div className="col-xs-12">
                                            {toBool(recommended.next.length) &&
                                            <ItemRecommended
                                                recommended={recommended}
                                                value={OFFICIAL_SERVICE_NEXT}
                                                change={this.handleChangeRecommended}
                                            />
                                            }
                                        </div>
                                    </div>

                                    <h4>{t('pages.form_steps.official_service.select_other_services')}</h4>

                                    {toBool((main.length || additional.length)) &&
                                    <div>
                                        <div className="official-services-main">
                                            {main.map((service, key) => <ItemMain change={this.handleChangeMain}
                                                                                  service={service} key={key}/>)}
                                        </div>

                                        <div className="official-services-additional">
                                            {additional.map((service, key) => <ItemAdditional
                                                change={this.handleChangeAdditional} service={service} key={key}/>)}
                                        </div>
                                    </div>
                                    }
                                </div>
                            }
                            {!loading &&
                            <FormStepSubmit
                                onBack={this.back}
                                onNext={this.submit}
                                disabled={creating}
                                loading={creating}
                            />
                            }
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        car: state.car,
        officialServices: state.officialServices,
    }
}

export default connect(mapStateToProps)(CarStepMiddleware(HasOfferToken(translate('translations')(OfficialServiceStepContainer))))
